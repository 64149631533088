@import "src/styles/variables";

.card-auth {
  position: relative;

  &__logo-wrap {
    width: 200px;
    height: 44px;

    img {
      width: 100%;
    }
  }

  &__header {
    padding-top: $space-6;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;

    &-text {
      font-weight: 400;
      font-size: 20px;
      line-height: 28px;
      color: $neutral5;
    }
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

}
